import { Menu } from "lucide-react";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Icon,
  Label,
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components";
import { useReportGroupsStore } from "@/store/reportPermissions";

export function NavigationMobile() {
  const reportGroups = useReportGroupsStore((store) => store.reportGroups);

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = (link: string) => {
    setIsSheetOpen(false);
    navigate(link);
  };

  return (
    <div className="flex items-center justify-start border-zinc-200 bg-white w-full border-b py-1 px-3">
      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetTrigger onClick={() => setIsSheetOpen(true)}>
          <Menu />
        </SheetTrigger>

        <SheetContent>
          <Accordion type="single" collapsible>
            <AccordionItem value={"0"}>
              <AccordionTrigger hideArrow onClick={() => handleNavigation("/")}>
                <div className="flex">
                  <Icon name={"LayoutDashboard"} size={18} />

                  <Label className="text-sm">Kick-off</Label>
                </div>
              </AccordionTrigger>
            </AccordionItem>

            {reportGroups?.map((menu, index) => {
              const reports = menu.reports.concat(menu.reportsApi);

              return (
                <AccordionItem value={`${index + 1}menu`} key={index}>
                  <AccordionTrigger>
                    <div className="flex">
                      <Icon name={menu.icon} size={18} />

                      <Label className="text-sm">{menu.name}</Label>
                    </div>
                  </AccordionTrigger>

                  <AccordionContent>
                    {reports.map((report, index) => {
                      return (
                        <Fragment key={index}>
                          {report.report_type ? (
                            <AccordionItem value={`${index}submenu-report`}>
                              <Link to={`/reports/${report.report_type}`}>
                                <AccordionTrigger hideArrow>
                                  <Label className="text-sm">
                                    {report.name}
                                  </Label>
                                </AccordionTrigger>
                              </Link>
                            </AccordionItem>
                          ) : (
                            <AccordionItem value={`${index}submenu-pbi`}>
                              <AccordionTrigger
                                hideArrow
                                onClick={() =>
                                  handleNavigation(
                                    `/reports/${report.dataset_id}/${report.report_id}`
                                  )
                                }
                              >
                                <Label className="text-sm">{report.name}</Label>
                              </AccordionTrigger>
                            </AccordionItem>
                          )}
                        </Fragment>
                      );
                    })}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </SheetContent>
      </Sheet>
    </div>
  );
}
