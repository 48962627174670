import { UserProps } from "@/types/user";
import { create } from "zustand";

interface UserStore {
  user: UserProps | null;
  setUser: (user: UserProps | null) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  user:
    typeof window !== "undefined" && localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!)
      : null,
  setUser: (user) => {
    set({ user });
    if (typeof window !== "undefined") {
      localStorage.setItem("user", JSON.stringify(user));
    }
  },
}));
