import { create } from "zustand";

interface AdminBreadcrumbStoreProps {
  setAdminBreadcrumb?: (paths) => void;
  paths: AdminBreadcrumbProps[];
}

interface AdminBreadcrumbProps {
  title: string;
  path: string;
}

interface RowProps {
  setRow: (row: any) => void;
  row: any;
}

const defaultPaths: AdminBreadcrumbProps[] = [
  { title: "Início", path: "/" },
  { title: "Área administrativa", path: "/administrative/company" },
];

export const useRowStore = create<RowProps>((set) => ({
  row: {},
  setRow: (row) => set({ row: row }),
}));

export const useSubRowStore = create<RowProps>((set) => ({
  row: {},
  setRow: (row) => set({ row: row }),
}));

export const useAdminBreadcrumbStore = create<AdminBreadcrumbStoreProps>(
  (set) => ({
    paths: [...defaultPaths],
    setAdminBreadcrumb: (newPaths) =>
      set({ paths: [...defaultPaths, ...newPaths] }),
  })
);
