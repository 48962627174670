import { ReportGroupProps } from "@/types/reports";
import { create } from "zustand";

interface ReportGroups {
  reportGroups: ReportGroupProps[];
  setReportGroups: (reportGroups: ReportGroupProps[]) => void;
}

export const useReportGroupsStore = create<ReportGroups>((set) => ({
  reportGroups: [],
  setReportGroups: (reportGroups) => set({ reportGroups }),
}));
