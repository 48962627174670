import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui";
import { version } from "../../../package.json";

export const VersionDisplay = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "m" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-gray-600">Flow Analytics</DialogTitle>
          <DialogDescription>v{version}</DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button
            type="submit"
            variant="primary"
            onClick={() => setOpen(false)}
          >
            Entendido!
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
