import { HelpCircle, LayoutGrid, Logs } from "lucide-react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../ui";
import { Link } from "react-router-dom";
import { useUserStore } from "@/store/user";
import { UserGroups } from "@/constants/user";

export const HeaderComplementaryApps = () => {
  const userRole = useUserStore((store) => store.user?.group);

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="ml-3 mr-1" hideArrow>
            <LayoutGrid className="h-6 w-6" />
          </NavigationMenuTrigger>

          <NavigationMenuContent>
            <ul className="p-0">
              <li>
                <NavigationMenuLink asChild>
                  <Link to="/help-center">
                    <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                      <HelpCircle className="h-6 w-6 text-gray-600" />
                      <span className="text-xs text-center text-gray-600 font-medium mt-2">
                        Central de Ajuda
                      </span>
                    </div>
                  </Link>
                </NavigationMenuLink>
              </li>

              {userRole !== UserGroups.normal && (
                <li>
                  <NavigationMenuLink asChild>
                    <Link to="/logs">
                      <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                        <Logs className="h-6 w-6 text-gray-600" />
                        <span className="text-xs text-center text-gray-600 font-medium mt-2">
                          Logs
                        </span>
                      </div>
                    </Link>
                  </NavigationMenuLink>
                </li>
              )}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
