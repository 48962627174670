import {
  addWeeks,
  endOfWeek,
  parseISO,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const getDateWithTimezone = (date) => {
  const timeZone = "UTC";

  let zonedDate;

  if (!date) date = new Date();

  if (typeof date === "string") {
    zonedDate = toZonedTime(parseISO(date), timeZone);
  } else if (date instanceof Date) {
    zonedDate = toZonedTime(date, timeZone);
  } else {
    throw new Error("Invalid date format");
  }

  return zonedDate;
};

export const getWeekRange = (weekNumber) => {
  const firstDayOfYear = startOfYear(getDateWithTimezone(new Date()));
  const startOfWeekDate = startOfWeek(
    addWeeks(firstDayOfYear, weekNumber - 1),
    { weekStartsOn: 0 }
  );
  const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 0 });

  return {
    from: startOfWeekDate,
    to: endOfWeekDate,
  };
};
