import { useCompanyStore } from "@/store/company";
import { usePostHog } from "posthog-js/react";
import { Suspense, lazy, useCallback, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Navbar, PageLoader, RequireAuth } from "./components";
import { VersionDisplay } from "./layouts/VersionDisplay/VersionDisplay";
import { useReportGroupsStore } from "./store/reportPermissions";
import { useUserStore } from "./store/user";

const AdministrativeAreaGoalsSales = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales"
    )
);
const AdminsitrativeAreaGoalsPurchases = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsPurchases"
    )
);
const AdministrativeAreaGoalsSalesDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales/AdministrativeAreaGoalsSalesDetails"
    )
);
const AdministrativeArea = lazy(() => import("./routes/AdministrativeArea"));
const DRE = lazy(() => import("./routes/Reports/Board/DRE"));
const BudgetAnalysis = lazy(
  () => import("./routes/Reports/Board/BudgetAnalysis")
);
const AdministrativeAreaCompanyInformation = lazy(
  () =>
    import("./routes/AdministrativeArea/AdministrativeAreaCompanyInformation")
);
const AdministrativeAreaCommissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCommissions")
);
const AdministrativeAreaPermissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaPermissions")
);
const AdministrativeAreaIndicators = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaIndicators")
);
const AdministrativeAreaParametrizationFinancial = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationFinancial"
    )
);
const AdministrativeAreaParametrizationPotential = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPotential"
    )
);
const AdministrativeAreaParametrizationSellers = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationSellers"
    )
);
const AdministrativeAreaParametrizationMarketClassification = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationMarketClassification"
    )
);
const AdministrativeAreaParametrizationReturns = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationReturns"
    )
);
const AdministrativeAreaParametrizationPortfolio = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPortfolio"
    )
);
const AdministrativeAreaParametrizationCalendar = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCalendar"
    )
);
const AdministrativeAreaUsers = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaUsers")
);
const AdministrativeAreaLogs = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaLogs")
);
const AdministrativeAreaCampaigns = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCampaigns")
);
const AdministrativeAreaCampaignsDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaCampaigns/AdministrativeAreaCampaignsDetails"
    )
);
const AmericanaReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/AmericanaReceivableMonitoring")
);
const ReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/ReceivableMonitoring")
);
const CurrentInventory = lazy(
  () => import("./routes/Reports/Inventory/CurrentInventory")
);
const TanksControl = lazy(
  () => import("./routes/Reports/Inventory/TanksControl")
);
const CustomerFrequency = lazy(
  () => import("./routes/Reports/Sales/CustomerFrequency")
);
const GlobalInventory = lazy(
  () => import("./routes/Reports/Inventory/GlobalInventory")
);
const MarketClassification = lazy(
  () => import("./routes/Reports/Sales/MarketClassification")
);
const SalesByLocation = lazy(
  () => import("./routes/Reports/Sales/SalesByLocation")
);
const Dashboard = lazy(() => import("./routes/Dashboard"));
const EvolutionCurve = lazy(
  () => import("./routes/Reports/Sales/EvolutionCurve")
);
const ExchangeCenter = lazy(
  () => import("./routes/Reports/Inventory/ExchangeCenter")
);
const PurchaseCenter = lazy(
  () => import("./routes/Reports/Inventory/PurchaseCenter")
);
const GmadCampaign = lazy(
  () => import("./routes/Reports/SalesSupervisor/GmadCampaigns")
);
const GmadRankingExtract = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupRankingExtract")
);
const GmadCampaignsExtract = lazy(
  () => import("./routes/Reports/StoreGroup/CampaignsExtract")
);
const GmadSalesmanProgram = lazy(
  () => import("./routes/Reports/StoreGroup/SalesmanProgram")
);
const GoalTracking = lazy(() => import("./routes/Reports/Sales/GoalTracking"));
const InventoryReturns = lazy(
  () => import("./routes/Reports/Inventory/InventoryReturns")
);
const Login = lazy(() => import("./routes/Login"));
const NotFound = lazy(() => import("./routes/NotFound"));
const RenderPowerBI = lazy(() => import("./routes/Reports/RenderPowerBI"));
const SalesAnalysis = lazy(
  () => import("./routes/Reports/Sales/SalesAnalysis")
);
const CampaignExtract = lazy(
  () => import("./routes/Reports/Sales/CampaignExtract")
);
const Sales1kLiters = lazy(
  () => import("./routes/Reports/Sales/Sales1kLiters")
);
const IndicatorBoard = lazy(
  () => import("./routes/Reports/Sales/IndicatorBoard")
);
const FuelDiscount = lazy(() => import("./routes/Reports/Sales/FuelDiscount"));
const GmadStoreGroup = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupStoreGroup")
);
const UserProfile = lazy(() => import("./routes/UserProfile"));
const PaymentSales = lazy(() => import("./routes/Reports/Sales/PaymentSales"));
const HelpCenter = lazy(() => import("./routes/HelpCenter"));
const Logs = lazy(() => import("./routes/Logs"));
const HelpCenterInitial = lazy(
  () => import("./routes/HelpCenter/HelpCenterInitial")
);
const HelpCenterFaq = lazy(() => import("./routes/HelpCenter/HelpCenterFaq"));
const HelpCenterVideoListByPlatform = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByPlatform")
);
const HelpCenterVideoListByCategory = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByCategory")
);
const HelpCenterVideo = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideo")
);
const FinancialAccountsControl = lazy(
  () => import("./routes/Reports/Financial/FinancialAccountsControl")
);
const AccountsControlCredit = lazy(
  () => import("./routes/Reports/Financial/AccountsControlCredit")
);
const AccountsControlExpense = lazy(
  () => import("./routes/Reports/Financial/AccountsControlExpense")
);
const AccountsControlReceive = lazy(
  () => import("./routes/Reports/Financial/AccountsControlReceive")
);
const Carriers = lazy(() => import("./routes/Reports/Financial/Carriers"));
const CashFlow = lazy(
  () => import("./routes/Reports/FinancialSupervisor/CashFlow")
);
const PortfolioManagement = lazy(
  () => import("./routes/Reports/Sales/PortfolioManagement")
);
const StoreGroupCampaings = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupCampaings")
);

export default function App() {
  const posthog = usePostHog();

  const storedUser = useUserStore((store) => store.user);
  const storedCompany = useCompanyStore((store) => store.company.nome_fantasia);

  useEffect(() => {
    posthog.identify(storedUser?.id.toString(), {
      email: storedUser?.email,
      name: storedUser?.first_name,
      company: storedCompany,
    });
  });

  return (
    <>
      <VersionDisplay />

      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/404" element={<NotFound />} />
          <Route
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/help-center" element={<HelpCenter />}>
              <Route path="/help-center" element={<HelpCenterInitial />} />
              <Route path="/help-center/faq" element={<HelpCenterFaq />} />
              <Route
                path="/help-center/plataforma/:platform"
                element={<HelpCenterVideoListByPlatform />}
              />
              <Route
                path="/help-center/categoria/:category"
                element={<HelpCenterVideoListByCategory />}
              />
              <Route
                path="/help-center/video/:platform/:category/:video"
                element={<HelpCenterVideo />}
              />
            </Route>
            <Route path="/administrative" element={<AdministrativeArea />}>
              <Route
                path="/administrative/company"
                element={<AdministrativeAreaCompanyInformation />}
              />
              <Route
                path="/administrative/campaigns"
                element={<AdministrativeAreaCampaigns />}
              />
              <Route
                path="/administrative/campaigns/:campaignId"
                element={<AdministrativeAreaCampaignsDetails />}
              />
              <Route
                path="/administrative/commissions"
                element={<AdministrativeAreaCommissions />}
              />
              <Route
                path="/administrative/goals/sales"
                element={<AdministrativeAreaGoalsSales />}
              />
              <Route
                path="/administrative/goals/sales/:goalId"
                element={<AdministrativeAreaGoalsSalesDetails />}
              />
              <Route
                path="/administrative/goals/purchases"
                element={<AdminsitrativeAreaGoalsPurchases />}
              />
              <Route
                path="/administrative/indicators"
                element={<AdministrativeAreaIndicators />}
              />
              <Route
                path="/administrative/permissions"
                element={<AdministrativeAreaPermissions />}
              />
              <Route
                path="/administrative/parameterization/potencial"
                element={<AdministrativeAreaParametrizationPotential />}
              />
              <Route
                path="/administrative/parameterization/financial"
                element={<AdministrativeAreaParametrizationFinancial />}
              />
              <Route
                path="/administrative/parameterization/sellers"
                element={<AdministrativeAreaParametrizationSellers />}
              />
              <Route
                path="/administrative/parameterization/market-classification"
                element={
                  <AdministrativeAreaParametrizationMarketClassification />
                }
              />
              <Route
                path="/administrative/parameterization/returns"
                element={<AdministrativeAreaParametrizationReturns />}
              />
              <Route
                path="/administrative/parameterization/portfolio"
                element={<AdministrativeAreaParametrizationPortfolio />}
              />
              <Route
                path="/administrative/parameterization/calendar"
                element={<AdministrativeAreaParametrizationCalendar />}
              />
              <Route
                path="/administrative/users"
                element={<AdministrativeAreaUsers />}
              />
              <Route
                path="/administrative/logs"
                element={<AdministrativeAreaLogs />}
              />
            </Route>
            <Route
              path="/reports/americana_inadimplencia"
              element={<AmericanaReceivableMonitoring />}
            />
            <Route
              path="/reports/rel-inadimplencia"
              element={<ReceivableMonitoring />}
            />
            <Route path="/reports/rel_dre" element={<DRE />} />
            <Route
              path="/reports/analise-orcamento"
              element={<BudgetAnalysis />}
            />
            <Route path="/reports/analise-vendas" element={<SalesAnalysis />} />
            <Route
              path="/reports/gestao-carteira"
              element={<PortfolioManagement />}
            />
            <Route
              path="/reports/rel-vendas-classificacao-mercadologica"
              element={<MarketClassification />}
            />
            <Route
              path="/reports/rel-vendas-localizacao"
              element={<SalesByLocation />}
            />
            <Route
              path="/reports/extrato-campanha"
              element={<CampaignExtract />}
            />
            <Route
              path="/reports/rel-vendas-pagamentos"
              element={<PaymentSales />}
            />
            <Route
              path="/reports/central-trocas"
              element={<ExchangeCenter />}
            />
            <Route
              path="/reports/central-compras"
              element={<PurchaseCenter />}
            />
            <Route
              path="/reports/rel_controle_contas"
              element={<FinancialAccountsControl />}
            />
            <Route
              path="/reports/rel_controle_credito"
              element={<AccountsControlCredit />}
            />
            <Route
              path="/reports/rel_controle_contas_pagar"
              element={<AccountsControlExpense />}
            />
            <Route
              path="/reports/rel_controle_contas_receber"
              element={<AccountsControlReceive />}
            />
            <Route path="/reports/portadores" element={<Carriers />} />
            <Route path="/reports/fluxo-caixa" element={<CashFlow />} />
            <Route
              path="/reports/cliente-frequencia"
              element={<CustomerFrequency />}
            />
            <Route
              path="/reports/curva-evolucao"
              element={<EvolutionCurve />}
            />
            <Route path="/reports/devolucoes" element={<InventoryReturns />} />
            <Route
              path="/reports/estoque-atual"
              element={<CurrentInventory />}
            />
            <Route
              path="/reports/inventario-estoque"
              element={<GlobalInventory />}
            />
            <Route
              path="/reports/controle-tanques"
              element={<TanksControl />}
            />
            <Route path="/reports/gmad-campanha" element={<GmadCampaign />} />
            <Route
              path="/reports/rel-campanhas-rede"
              element={<StoreGroupCampaings />}
            />
            <Route
              path="/reports/rel-loja-grupo"
              element={<GmadStoreGroup />}
            />
            <Route
              path="/reports/rel-programa-vendedores"
              element={<GmadSalesmanProgram />}
            />
            <Route
              path="/reports/rel-extrato-ranking"
              element={<GmadRankingExtract />}
            />
            <Route
              path="/reports/rel-extrato-campanha"
              element={<GmadCampaignsExtract />}
            />
            <Route path="/reports/rel-metas" element={<GoalTracking />} />
            <Route
              path="/reports/rel-vendas-mil-litros"
              element={<Sales1kLiters />}
            />
            <Route
              path="/reports/rel-postos-quadro-indicadores"
              element={<IndicatorBoard />}
            />
            <Route
              path="/reports/rel-descontos-combustiveis"
              element={<FuelDiscount />}
            />
            <Route
              path="/reports/:datasetId/:reportId/:reportName"
              element={<RenderPowerBI />}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const reportGroups = useReportGroupsStore((store) => store.reportGroups);

  const hasReport = useCallback(() => {
    return reportGroups.some((group) => {
      const groupReports = group.reportsApi.concat(group.reports);

      if (groupReports.length > 0) {
        return groupReports.some((report) => {
          return (
            pathname.includes(report.report_type) ||
            pathname.includes(report.report_id)
          );
        });
      }
    });
  }, [pathname, reportGroups]);

  useEffect(() => {
    if (!hasReport) {
      navigate("/");
    }
  }, [hasReport, navigate]);

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}
