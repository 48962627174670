import { useMutation, useQuery } from "react-query";

import { getApi } from ".";
import { ReportGroupProps } from "@/types/reports";

import { ReportTokenProps } from "@/types/pbToken";
import {
  CreateReportSettingsProps,
  UpdateReportSettingsProps,
} from "@/types/reports/reports";

interface ReportSettingsProps {
  reportSettingsSlug: string;
  goalSettings: CreateReportSettingsProps | UpdateReportSettingsProps;
}

export const useQueryListReportGroups = (companyId: number, options: object) =>
  useQuery<ReportGroupProps[]>(
    ["queryListReportGroups", companyId],
    async () => {
      const api = await getApi();

      return api
        .get(`/v2/reportgroups/${companyId}`)
        .then((result) => result.data);
    },
    options
  );

export const useQueryGetReportSettings = (
  reportSlug: string = "",
  options: object
) =>
  useQuery(
    ["queryGetReportSettings", reportSlug],
    async () => {
      const api = await getApi();

      return api
        .get(`/reports/settings/grid/${reportSlug}`)
        .then((result) => result.data);
    },
    { enabled: !!reportSlug, ...options }
  );

export const useMutationCreateReportSettings = (options: object) =>
  useMutation(
    async ({ reportSettingsSlug, goalSettings }: ReportSettingsProps) => {
      const api = await getApi();

      return api
        .post(`/reports/settings/grid/${reportSettingsSlug}`, goalSettings)
        .then((result) => result.data);
    },
    options
  );

export const useMutationUpdateReportSettings = (options: object) =>
  useMutation(
    async ({ reportSettingsSlug, goalSettings }: ReportSettingsProps) => {
      const api = await getApi();

      return api
        .put(`/reports/settings/grid/${reportSettingsSlug}`, goalSettings)
        .then((result) => result.data);
    },
    options
  );

export const useMutationDeleteReportSettings = (options: object) =>
  useMutation(async (settingId: number) => {
    const api = await getApi();

    return api
      .delete(`/reports/settings/grid/${settingId}`)
      .then((result) => result.data);
  }, options);

export const useMutationGetReportToken = (options: object) =>
  useMutation(
    async ({ reportId, datasetId, accessToken }: ReportTokenProps) => {
      const api = await getApi("pbi");

      return api
        .post(
          `/v1.0/myorg/GenerateToken`,
          {
            datasets: [{ id: datasetId }],
            reports: [{ allowEdit: true, id: reportId }],
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then((response) => response.data);
    },
    options
  );
