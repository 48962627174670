import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Icon,
  Label,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  Skeleton,
} from "@/components";
import { cn } from "@/lib/utils";
import { useQueryListReportGroups } from "@/service/reports";
import { useCompanyStore } from "@/store/company";
import { useReportGroupsStore } from "@/store/reportPermissions";
import { MenuReportProps, ReportGroupProps } from "@/types/reports";

export function Navigation() {
  const { pathname } = useLocation();

  const { company: companySelected } = useCompanyStore((state) => state);
  const setReportGroups = useReportGroupsStore(
    (store) => store.setReportGroups
  );
  const reportGroups = useReportGroupsStore((store) => store.reportGroups);

  function onNavChange() {
    setTimeout(() => {
      const triggers = document.querySelectorAll(
        '.submenu-trigger[data-state="open"]'
      );
      if (triggers.length === 0) return;

      const firstTrigger = triggers[0] as HTMLElement;
      const viewports = document.getElementsByClassName("submenu-viewport");

      if (viewports.length > 0) {
        const viewport = viewports[0] as HTMLElement;
        viewport.style.left = `${firstTrigger.offsetLeft}px`;
      }
    });
  }

  const { isLoading: isLoadingListReportGroups } = useQueryListReportGroups(
    companySelected.id,
    {
      onSuccess: (data) => {
        setReportGroups(data);
      },
    }
  );

  const handleHighlightMenu = (menuProps: ReportGroupProps) => {
    const { reportsApi, reports } = menuProps;

    const highlightMenu = reportsApi
      .concat(reports)
      .some((report: MenuReportProps) => {
        return (
          pathname.includes(report.report_type) ||
          pathname.includes(report.report_id)
        );
      });

    if (highlightMenu) return "bg-brand-blue-main text-white";

    return "text-gray-700";
  };

  if (isLoadingListReportGroups) {
    return (
      <div className="flex items-center justify-center border-zinc-200 bg-white w-full border-b py-1 gap-x-3">
        {new Array(7).fill(null).map((_, index) => {
          return <Skeleton key={index} className="w-20 h-8" />;
        })}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center border-zinc-200 bg-white w-full border-b py-1">
      <NavigationMenu onValueChange={onNavChange}>
        <NavigationMenuList>
          <NavigationMenuItem>
            <Link to="/">
              <NavigationMenuTrigger
                className={`px-2 py-0 max-h-8 submenu-trigger ${
                  pathname === "/"
                    ? "bg-brand-blue-main text-white"
                    : "text-gray-700"
                }`}
                hideArrow
              >
                <div>
                  <Icon name={"LayoutDashboard"} size={15} />
                </div>
                <Label className="text-xs">Kick-off</Label>
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem>

          {reportGroups?.map((menu) => {
            const reports = menu.reports.concat(menu.reportsApi);

            if (reports.length === 0) {
              return null;
            }

            return (
              <NavigationMenuItem key={menu.id}>
                <NavigationMenuTrigger
                  className={`submenu-trigger px-2 py-0 max-h-7 ${handleHighlightMenu(
                    menu
                  )}`}
                >
                  <div>
                    <Icon name={menu.icon} size={15} />
                  </div>

                  <Label className="text-xs">{menu.name}</Label>
                </NavigationMenuTrigger>

                <NavigationMenuContent>
                  <ul className="flex max-w-[450px] max-h-[50vh] overflow-auto flex-wrap text-gray-700">
                    {reports
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((report) =>
                        report.report_type ? (
                          <ListItem
                            key={report.report_uuid}
                            title={report.name}
                            href={`/reports/${report.report_type}`}
                          />
                        ) : (
                          <li
                            className="my-1 min-w-[300px]"
                            key={report.report_id}
                          >
                            <a
                              href={`/reports/${report.dataset_id}/${report.report_id}/${report.name}`}
                              className={cn(
                                "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-brand-blue-main hover:text-white focus:bg-accent focus:text-white py-1"
                              )}
                            >
                              <div className="text-xs font-medium leading-5">
                                {report.name}
                              </div>
                            </a>
                          </li>
                        )
                      )}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            );
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li className="min-w-[300px]">
      <Link
        to={props.href || "/"}
        ref={ref}
        className={cn(
          "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-brand-blue-main hover:text-white focus:bg-accent focus:text-white py-1",
          className
        )}
        {...props}
      >
        <div className="text-xs font-medium leading-5">{title}</div>
        <div className="mt-2 line-clamp-2 text-sm leading-snug">{children}</div>
      </Link>
    </li>
  );
});

ListItem.displayName = "ListItem";
