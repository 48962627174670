import { ReactElement } from "react";

import { TanstackQuery } from "./TanstackQuery";
import { Toaster, TooltipProvider } from "@/components";

import { PostHogProvider } from "posthog-js/react";

import { BrowserRouter } from "react-router-dom";

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};
interface ProvidersProps {
  children: ReactElement;
}

export const Providers = ({ children }: ProvidersProps) => {
  return (
    <BrowserRouter>
      <TanstackQuery>
        <PostHogProvider
          apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <TooltipProvider delayDuration={50}>
            <Toaster />
            {children}
          </TooltipProvider>
        </PostHogProvider>
      </TanstackQuery>
    </BrowserRouter>
  );
};
