import { getDateWithTimezone } from "@/utils/intlDate";
import { cva, VariantProps } from "class-variance-authority";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";

const today = getDateWithTimezone(new Date());

export const dateRanges = [
  { label: "Hoje", start: today, end: today },
  { label: "Ontem", start: subDays(today, 1), end: subDays(today, 1) },
  {
    label: "Essa semana",
    start: startOfWeek(today, { weekStartsOn: 0 }),
    end: endOfWeek(today, { weekStartsOn: 0 }),
  },
  {
    label: "Semana passada",
    start: subDays(startOfWeek(today, { weekStartsOn: 0 }), 7),
    end: subDays(endOfWeek(today, { weekStartsOn: 0 }), 7),
  },
  {
    label: "Últimos 3 meses",
    start: startOfMonth(subMonths(today, 2)),
    end: endOfMonth(today),
  },
  {
    label: "Este mês",
    start: startOfMonth(today),
    end: endOfMonth(today),
  },
  {
    label: "Mês passado",
    start: startOfMonth(subDays(today, today.getDate())),
    end: endOfMonth(subDays(today, today.getDate())),
  },
  { label: "Este ano", start: startOfYear(today), end: endOfYear(today) },
  {
    label: "Ano passado",
    start: startOfYear(subDays(today, 365)),
    end: endOfYear(subDays(today, 365)),
  },
];

export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const multiSelectVariants = cva(
  "flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium text-foreground ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground text-background",
        link: "text-primary underline-offset-4 hover:underline text-background",
      },
    },
    defaultVariants: {
      variant: "link",
    },
  }
);

export interface CalendarDatePickerProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  id?: string;
  className?: string;
  date?: { from: Date; to: Date };
  closeOnSelect?: boolean;
  isFormField?: boolean;
  numberOfMonths?: 1 | 2;
  yearsRange?: number;
  onDateSelect: (date: { from: Date; to: Date }) => void;
}
