import { CogIcon, icons } from "lucide-react";

interface IconProps {
  name: string;
  size?: number;
}

export const Icon = ({ name, size }: IconProps) => {
  const LucideIcon = (icons as any)[name];

  if (!LucideIcon) {
    return (
      <div className="mx-1">
        <CogIcon size={size || 18} />
      </div>
    );
  }

  return (
    <div className="mx-1">
      <LucideIcon size={size || 18} />
    </div>
  );
};
