import { Avatar, AvatarFallback } from "..";

interface UserAvatarProps {
  userInformation: {
    first_name: string;
    last_name: string;
  };
}

export const UserAvatar = ({ userInformation }: UserAvatarProps) => {
  return (
    <Avatar className="w-8 h-8">
      <AvatarFallback className="bg-brand-blue-main font-semibold text-white text-sm">
        {`${userInformation.first_name.substring(
          0,
          1
        )}${userInformation.last_name.substring(0, 1)}`}
      </AvatarFallback>
    </Avatar>
  );
};
