import { Header } from "./Header";
import { Navigation } from "./Navigation";
import { NavigationMobile } from "./NavigationMobile";

export const Navbar = () => {
  return (
    <>
      <Header />

      <div className="max-lg:hidden lg:flex w-full">
        <Navigation />
      </div>

      <div className="max-lg:flex lg:hidden">
        <NavigationMobile />
      </div>
    </>
  );
};
