import { ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components";
import { cn } from "@/lib/utils";
import { useQueryListUserCompanies } from "@/service/companies";
import { useCompanyStore } from "@/store/company";
import { useReportGroupsStore } from "@/store/reportPermissions";
import { CompanyProps } from "@/types/company";

export const CompanyCombobox = () => {
  const setReportGroups = useReportGroupsStore(
    (store) => store.setReportGroups
  );
  const { setCompany, company: companySelected } = useCompanyStore(
    (state) => state
  );
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(companySelected.id);

  const { data, isLoading } = useQueryListUserCompanies({});

  useEffect(() => {
    if (companySelected.id !== 0) {
      setValue(companySelected.id);
    }
  }, [companySelected, navigate]);

  const handleOpenPopover = () => {
    if (data?.length === 1) {
      setOpen(false);
      return;
    }

    if (isLoading) {
      setOpen(false);
      return;
    }

    setOpen(true);
  };

  const handleSelectCompany = (company: CompanyProps) => {
    setReportGroups([]);
    setCompany(company);
    setValue(company.id);
    setOpen(false);
    navigate("/");
  };

  const handleClickOutside = (event: MouseEvent) => {
    const popoverContent = document.querySelector(".popover-content-class");
    const popoverTrigger = document.querySelector(".popover-trigger-class");

    if (
      popoverContent &&
      popoverTrigger &&
      !popoverContent.contains(event.target as Node) &&
      !popoverTrigger.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Popover open={open} onOpenChange={handleOpenPopover}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between popover-trigger-class px-2 py-0 max-h-7 w-[285px] max-md:w-[100px]"
        >
          <span className="text-xs">
            {value
              ? data?.find((company) => company.id === value)?.nome_fantasia
              : "Selecione a empresa..."}
          </span>

          {data?.length === 1 ? null : (
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50 " />
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className=" p-0 popover-content-class">
        <Command>
          <CommandInput placeholder="Procure a empresa..." />
          <CommandEmpty>Nenhuma empresa encontrada</CommandEmpty>

          <CommandGroup className="max-h-[300px] overflow-y-scroll">
            {data?.map((company) => (
              <CommandItem
                key={company.id}
                value={company.nome_fantasia}
                onSelect={() => {
                  handleSelectCompany(company);
                }}
              >
                <span
                  className={cn(
                    "text-sm",
                    value === company.id ? "font-semibold" : "font-normal"
                  )}
                >
                  {company.nome_fantasia}
                </span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
