import useAuthentication from "@/hooks/useAuthentication";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isInitialized } = useAuthentication();
  const location = useLocation();

  if (!isAuthenticated && isInitialized) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
