import axios from "axios";
import { useMutation, useQuery } from "react-query";

import { ChangePasswordProps } from "@/types/changePassword";
import { ReportLogProps } from "@/types/reportLog";
import { UserMobileAccess, UserProps } from "@/types/user";
import { getApi } from ".";

interface UserLoginProps {
  username: string;
  password: string;
}

export const useMutationAuthUser = (options: object) =>
  useMutation(async (userData: UserLoginProps) => {
    return axios
      .post(`${import.meta.env.VITE_API_HOST}/token/`, { ...userData })
      .then((result) => result.data);
  }, options);

export const useQueryUserInformation = (options: object) =>
  useQuery(
    ["queryUserInformation"],
    async () => {
      const api = await getApi();

      return api.get("/mobile/user").then((result) => result.data);
    },
    options
  );

export const useQueryGetSellerType = (
  companyId: number,
  skVendedor: number | null,
  options: object
) =>
  useQuery(
    ["queryGetSellerType", companyId, skVendedor],
    async () => {
      const api = await getApi();

      const params = {
        report: "get_seller_type",
        companyId,
        skVendedor: skVendedor || -1,
      };

      return api
        .post(`/mobile/report/query_v2`, params)
        .then((result) => result.data.dados);
    },
    options
  );

export const useQueryGetUserPermission = (
  companyId: number,
  userId: number | undefined,
  role: string | undefined,
  options: object
) =>
  useQuery<UserMobileAccess>(
    ["queryGetUserPermission", companyId, userId, role],
    async () => {
      const api = await getApi("admin");

      return await api
        .post("/mobile/report/access", {
          companyId,
          role,
          userId,
        })
        .then((response) => response.data);
    },
    options
  );

export const useQueryPowerBiToken = (options: object) => {
  useQuery(
    ["queryPowerBiToken"],
    async () => {
      const api = await getApi();

      return api.get("pb-token").then((pbToken) => pbToken.data);
    },
    options
  );
};

export const useQueryGetIP = (options: object) => {
  useQuery(
    ["queryGetIP"],
    async () => {
      return await axios
        .get("https://api.ipify.org/?format=json")
        .then((ip) => ip.data);
    },
    options
  );
};

export const useQueryCheckIP = (
  companyId: number,
  clientIp: string,
  options: object
) => {
  useQuery(
    ["queryCheckIP", companyId, clientIp],
    async () => {
      const api = await getApi("admin");
      return api
        .post("/check-ip/", {
          companyId,
          clientIp,
        })
        .then((response) => response.data);
    },
    { refetchInterval: 600000, ...options }
  );
};

export const useMutationChangePassword = (options: object) =>
  useMutation(async ({ oldPassword, newPassword }: ChangePasswordProps) => {
    const api = await getApi();
    return api
      .post("/change-password/", {
        oldPassword,
        newPassword,
      })
      .then((result) => result.data);
  }, options);

export const useMutationSetReportLog = (options: object) =>
  useMutation(
    async ({ reportId, companyId, accessIp, reportType }: ReportLogProps) => {
      const api = await getApi();
      return api
        .post("/reportlog/", {
          reportId,
          companyId,
          accessIp,
          type: reportType,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationResetPassword = (options: object) =>
  useMutation(async (userEmail: string) => {
    return axios
      .post(`${import.meta.env.VITE_API_HOST}/user-recover/`, {
        email: userEmail,
      })
      .then((result) => result.data);
  }, options);

export const useQueryUserEstabsNotIn = (
  companyId: number,
  user: UserProps,
  options: object
) =>
  useQuery(
    ["queryUserEstabsNotIn", companyId, user],
    async () => {
      const api = await getApi();

      const params = {
        companyId: companyId,
        report: "get_user_estabs",
        role: user.group,
        userId: user.id,
      };

      return api
        .post("/mobile/report/query_v2", { ...params })
        .then((result) => result.data.dados);
    },
    { enabled: !!user?.id && companyId !== 0, ...options }
  );
