import { create } from "zustand";

interface EstabsNotInProps {
  ESTAB_NOT_IN: number[];
  SKEMPRESA: number;
}

interface EstabsNotInStore {
  estabsNotIn: EstabsNotInProps;
  setEstabsNotIn: (company: EstabsNotInProps) => void;
}

export const useEstabsNotInStore = create<EstabsNotInStore>((set) => ({
  estabsNotIn: {
    ESTAB_NOT_IN: [-1],
    SKEMPRESA: -1,
  },
  setEstabsNotIn: (estabsNotIn) => set({ estabsNotIn }),
}));
