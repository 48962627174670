import faLogo from "@/assets/fa-logo-symbol.png";
import { PB_TOKEN, USER_IP } from "@/constants";
import { UserGroups } from "@/constants/user";
import useAuthentication from "@/hooks/useAuthentication";
import { useUserGroupCredentials } from "@/hooks/useUserGroupCredentials";
import {
  useQueryCheckIP,
  useQueryGetIP,
  useQueryGetSellerType,
  useQueryGetUserPermission,
  useQueryPowerBiToken,
  useQueryUserEstabsNotIn,
  useQueryUserInformation,
} from "@/service/auth";
import { useCompanyStore } from "@/store/company";
import { useEstabsNotInStore } from "@/store/estabsNotIn";
import { useUserStore } from "@/store/user";
import { UserMobileAccess, UserProps } from "@/types/user";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  CompanyCombobox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  UserAvatar,
  useToast,
} from "..";
import { HeaderComplementaryApps } from "./HeaderComplementaryApps";

export const Header = () => {
  const { toast } = useToast();
  const { logout } = useAuthentication();
  const userSelected = useUserStore((state) => state.user);
  const setUserSelected = useUserStore((state) => state.setUser);
  const setEstabsNotIn = useEstabsNotInStore((store) => store.setEstabsNotIn);

  const { company: companySelected, setCompany } = useCompanyStore(
    (state) => state
  );
  const { sellerId } = useUserGroupCredentials(userSelected);

  const [isOpen, setIsOpen] = useState(false);

  const ipData = JSON.parse(localStorage.getItem(USER_IP)!);

  const { data: dataUser } = useQueryUserInformation({
    onSuccess: (data: UserProps) => {
      setUserSelected({ ...userSelected, ...data });
    },
  });

  useQueryGetSellerType(companySelected.id, sellerId, {
    enabled: !!userSelected && companySelected.id !== 0,
    onSuccess: (data) => {
      if (userSelected) {
        setUserSelected({ ...userSelected, sellerType: data[0].SELLER_TYPE });
      }
    },
  });

  useQueryPowerBiToken({
    enabled: companySelected?.id !== 0,
    onSuccess: (pbToken: object) => {
      localStorage.setItem(PB_TOKEN, JSON.stringify(pbToken));
    },
  });

  useQueryGetIP({
    onSuccess: (ip: string) => {
      localStorage.setItem(USER_IP, JSON.stringify(ip));
    },
  });

  const { data: dataUserPermission } = useQueryGetUserPermission(
    companySelected?.id,
    userSelected?.id,
    userSelected?.group,
    {
      enabled:
        !!companySelected?.id && !!userSelected?.id && !!userSelected?.group,
      onSuccess: (data: UserMobileAccess) => {
        if (userSelected) {
          setUserSelected({
            ...userSelected,
            permissions: data.access,
          });
        }
      },
    }
  );

  useQueryCheckIP(companySelected.id, ipData?.ip, {
    enabled: !!dataUserPermission?.access.check_ip,
    onSuccess: (response: { error: string }) => {
      if (response.error) {
        logout();
        toast({
          variant: "destructive",
          title: "Você não tem acesso deste IP!",
          description: "Verifique com seu supervisor e tente novamente",
        });
      }
    },
  });

  useQueryUserEstabsNotIn(companySelected.id, dataUser, {
    onSuccess: (data: { SKEMPRESA: number; ESTAB_NOT_IN }) => {
      setCompany({
        ...companySelected,
      });

      if (Array.isArray(data[0].ESTAB_NOT_IN)) {
        setEstabsNotIn({
          ESTAB_NOT_IN: [...data[0].ESTAB_NOT_IN],
          SKEMPRESA: data[0].SKEMPRESA,
        });

        return;
      }

      setEstabsNotIn({
        ESTAB_NOT_IN: [data[0].ESTAB_NOT_IN],
        SKEMPRESA: data[0].SKEMPRESA,
      });
    },
  });

  return (
    <div className="flex w-full items-center justify-center border-b border-zinc-200 bg-white">
      <div className="flex h-10 w-full items-center justify-between max-w-[110rem] px-10">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={faLogo}
              alt="Logo da Flow Analytics"
              width={25}
              height={25}
            />

            <span className="mx-2 text-sm font-semibold text-gray-600 max-md:hidden">
              <strong>flow</strong>analytics
            </span>
          </div>
        </Link>

        <div className="flex items-center gap-1">
          <CompanyCombobox />

          <HeaderComplementaryApps />

          {userSelected && (
            <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
              <DropdownMenuTrigger>
                <UserAvatar userInformation={userSelected} />
              </DropdownMenuTrigger>

              <DropdownMenuContent>
                <DropdownMenuLabel>{`Olá ${userSelected?.first_name}`}</DropdownMenuLabel>
                <DropdownMenuSeparator />

                <DropdownMenuItem>
                  <Link
                    to={
                      userSelected.group === UserGroups.admin
                        ? "/administrative/company"
                        : "/administrative/parameterization/potencial"
                    }
                    onClick={() => setIsOpen(false)}
                  >
                    Área administrativa
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuItem>
                  <Link to="/profile" onClick={() => setIsOpen(false)}>
                    Meu perfil
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => logout()}>
                  Sair
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
};
