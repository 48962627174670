import { UserGroups } from "@/constants/user";
import { UserProps } from "@/types/user";

export const useUserGroupCredentials = (
  userInformation: UserProps | null
): { sellerId: number | null; supervisorId: number | null } => {
  if (userInformation) {
    let sellerId: number | null = null;
    let supervisorId: number | null = null;

    if (
      userInformation.group === UserGroups.normal &&
      !userInformation.salesman_id
    ) {
      sellerId = -420;
    }

    if (
      userInformation.group === UserGroups.normal &&
      userInformation.salesman_id
    ) {
      sellerId = userInformation.salesman_id;
    }

    if (userInformation.group === UserGroups.supervisor) {
      supervisorId = userInformation.salesman_id;
    }

    return { sellerId, supervisorId };
  }

  return { sellerId: null, supervisorId: null };
};
